import {gql} from "@apollo/client/core";

export const COUNT_BY_PATH = gql`
query ($date: Date $tenantId: ID!) {
  callsConnection(where: { created_at_gt: $date tenant: $tenantId} limit: 10) {
    groupBy {
      path {
        key
        connection {
          aggregate {
            count
          }
        }
      }
    }
  }
}
`

export const COUNT_BY_REFERRER = gql`
query ($date: Date $tenantId: ID!) {
  callsConnection(where: { created_at_gt: $date tenant: $tenantId } limit: 10) {
    groupBy {
      referrer {
        key
        connection {
          aggregate {
            count
          }
        }
      }
    }
  }
}
`

export const COUNT_BY_COUNTRIES = gql`
query ($date: Date $tenantId: ID!) {
  callsConnection( where: { created_at_gt: $date tenant: $tenantId } limit: 10) {
    groupBy {
      countryCode {
        key
        connection {
          aggregate {
            count
          }
        }
      }
    }
  }
}
`

export const COUNT_BY_LANGUAGES = gql`
query ($date: Date $tenantId: ID!) {
  callsConnection( where: { created_at_gt: $date tenant: $tenantId } limit: 10) {
    groupBy {
      language {
        key
        connection {
          aggregate {
            count
          }
        }
      }
    }
  }
}
`

export const COUNT_BY_BROWSERS = gql`
query ($date: Date $tenantId: ID!) {
  callsConnection( where: { created_at_gt: $date tenant: $tenantId } limit: 10) {
    groupBy {
      browser {
        key
        connection {
          aggregate {
            count
          }
        }
      }
    }
  }
}`

export const COUNT_BY_OS = gql`
query ($date: Date $tenantId: ID!) {
  callsConnection( where: { created_at_gt: $date tenant: $tenantId } limit: 10) {
    groupBy {
      os {
        key
        connection {
          aggregate {
            count
          }
        }
      }
    }
  }
}`

export const COUNT_BY_SCREENCLASS = gql`
query ($date: Date $tenantId: ID!) {
  callsConnection( where: { created_at_gt: $date tenant: $tenantId } limit: 10) {
    groupBy {
      screenClass {
        key
        connection {
          aggregate {
            count
          }
        }
      }
    }
  }
}`

export const PAGE_VIEWS = gql`
query($date: Date $tenantId: ID!) {
  callsConnection(where: { created_at_gt: $date tenant: $tenantId }) {
    aggregate {
      count
    }
  }
}`

export const UNIQUE_VISITORS = gql`
query($date: Date $tenantId: ID!) {
  callsConnection(where: { created_at_gt: $date tenant: $tenantId }) {
    groupBy {
      fingerprint {
        key
      }
    }
  }
}`

export const SESSIONS_PER_DAY = gql`
query($date: Date $tenantId: ID!) {
  callsConnection(where: { previousTimeOnPageSeconds_gt: 0, created_at_gt: $date tenant: $tenantId }) {
    groupBy {
      session {
        key
        connection {
          aggregate {
            count
          }
        }
      }
    }
  }
}`