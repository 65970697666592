import React, {useEffect} from "react";
import {useLazyQuery} from "@apollo/client";

function BestOf(prop) {

    const [loadPages, { queryLoading, queryError, data }] = useLazyQuery(prop.graphQL)

    useEffect(() => {
        loadPages( {
            variables: {
                date: prop.date,
                tenantId: prop.tenantId
            }
        })
    }, []);


    if (queryLoading) {
        console.log(queryLoading)
        return <div>Loading</div>
    }

    if (queryError != null) {
        console.log(queryError)
        return <div>Error</div>
    }

    let result
    if (data != null) {
        result = data.callsConnection.groupBy[prop.type].sort((a,b) => (a.connection.aggregate.count < b.connection.aggregate.count) ? 1 : -1)
    }

    return (
        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">{prop.title}</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">{prop.subTitle}</p>
            </div>
            { result && (
                <ul className="divide-y divide-gray-200">
                    {result.map((page) => (
                        <li
                            key={page.key}
                            className="relative bg-white py-2 px-0 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600"
                        >
                            <div className="flex justify-between space-x-3">
                                <div className="min-w-0 flex-1">
                                    <div className="block focus:outline-none">
                                        <span className="absolute inset-0" aria-hidden="true" />
                                        <p className="text-sm font-medium text-gray-900 truncate">{page.key}</p>
                                        <p className="text-sm text-gray-500 truncate">

                                        </p>
                                    </div>
                                </div>
                                <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                                    {page.connection.aggregate.count}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default BestOf