import {useLazyQuery} from "@apollo/client";
import React, {useEffect} from "react";

function SessionsPerDay(prop) {

    const [loadPages, { queryLoading, queryError, data }] = useLazyQuery(prop.graphQL)

    useEffect(() => {
        loadPages( {
            variables: {
                date: prop.date,
                tenantId: prop.tenantId
            }
        })
    }, []);


    if (queryLoading) {
        console.log(queryLoading)
        return <div>Loading</div>
    }

    if (queryError != null) {
        console.log(queryError)
        return <div>Error</div>
    }

    let count = 0;
    if (data != null ) {
        data.callsConnection.groupBy.session.map(session => {
            count += session.connection.aggregate.count
        })
    }

    return(
        <>
            {data && (
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">{prop.title}</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{count}</dd>
                </div>
            )}
        </>
    )
}

export default SessionsPerDay