import React, {useEffect} from "react";
import {useLazyQuery} from "@apollo/client";

function UniqueVisitors(prop) {

    const [loadPages, { queryLoading, queryError, data }] = useLazyQuery(prop.graphQL)

    useEffect(() => {
        loadPages( {
            variables: {
                date: prop.date,
                tenantId: prop.tenantId
            }
        })
    }, []);


    if (queryLoading) {
        console.log(queryLoading)
        return <div>Loading</div>
    }

    if (queryError != null) {
        console.log(queryError)
        return <div>Error</div>
    }

    return(
        <>
            {data && (
                <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 truncate">{prop.title}</dt>
                    <dd className="mt-1 text-3xl font-semibold text-gray-900">{data['callsConnection']['groupBy']['fingerprint'].length}</dd>
                </div>
            )}
        </>
    )
}

export default UniqueVisitors