import React from 'react'
import BestOf from "../../components/statistics/bestOf";
import {
    COUNT_BY_BROWSERS,
    COUNT_BY_COUNTRIES,
    COUNT_BY_LANGUAGES, COUNT_BY_OS,
    COUNT_BY_PATH,
    COUNT_BY_REFERRER, COUNT_BY_SCREENCLASS, PAGE_VIEWS, SESSIONS_PER_DAY, UNIQUE_VISITORS
} from "../../graphql/statisticsGraphQL";
import PageViews from "../../components/statistics/pageViews";
import UniqueVisitors from "../../components/statistics/uniqueVisitors";
import SessionsPerDay from "../../components/statistics/sessionsPerDay";
import {getUserTenant} from "../../services/auth";

export default function Dashboard() {

    let date = new Date();
    date.setDate(date.getDate() - 30);

    return (
        <main className="max-w-7xl mx-auto">
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">

                </aside>

                {/* dashboard details */}
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Last 30 days</h3>
                        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                            <PageViews
                                date={date.toISOString().slice(0, 10)}
                                graphQL={PAGE_VIEWS}
                                tenantId={getUserTenant().id}
                                title={"Page Views"}
                            />
                            <UniqueVisitors
                                date={date.toISOString().slice(0, 10)}
                                graphQL={UNIQUE_VISITORS}
                                tenantId={getUserTenant().id}
                                title={"Unique Visitors"}
                            />
                            <SessionsPerDay
                                date={date.toISOString().slice(0, 10)}
                                graphQL={SESSIONS_PER_DAY}
                                tenantId={getUserTenant().id}
                                title={"Sessions"}
                            />
                        </dl>
                    </div>
                    <div>
                        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">

                            <BestOf
                                date={date.toISOString().slice(0, 10)}
                                graphQL={COUNT_BY_PATH}
                                type={'path'}
                                title={"Pages"}
                                tenantId={getUserTenant().id}
                                subTitle={"Amount of unique users separated by path."}
                            />

                            <BestOf
                                date={date.toISOString().slice(0, 10)}
                                graphQL={COUNT_BY_REFERRER}
                                type={'referrer'}
                                title={"Refers"}
                                tenantId={getUserTenant().id}
                                subTitle={"Amount of unique users separated by refer."}
                            />

                            <BestOf
                                date={date.toISOString().slice(0, 10)}
                                graphQL={COUNT_BY_COUNTRIES}
                                type={'countryCode'}
                                title={"Countries"}
                                tenantId={getUserTenant().id}
                                subTitle={"Amount of unique users separated by country."}
                            />

                            <BestOf
                                date={date.toISOString().slice(0, 10)}
                                graphQL={COUNT_BY_LANGUAGES}
                                type={'language'}
                                title={"Languages"}
                                tenantId={getUserTenant().id}
                                subTitle={"Amount of unique users separated by language."}
                            />

                            <BestOf
                                date={date.toISOString().slice(0, 10)}
                                graphQL={COUNT_BY_BROWSERS}
                                type={'browser'}
                                title={"Browsers"}
                                tenantId={getUserTenant().id}
                                subTitle={"Amount of unique users separated by browser."}
                            />

                            <BestOf
                                date={date.toISOString().slice(0, 10)}
                                graphQL={COUNT_BY_OS}
                                type={'os'}
                                title={"Operating Systems"}
                                tenantId={getUserTenant().id}
                                subTitle={"Amount of unique users separated by operating system."}
                            />

                            <BestOf
                                date={date.toISOString().slice(0, 10)}
                                graphQL={COUNT_BY_OS}
                                type={'os'}
                                title={"Operating Systems"}
                                tenantId={getUserTenant().id}
                                subTitle={"Amount of unique users separated by operating system."}
                            />

                            <BestOf
                                date={date.toISOString().slice(0, 10)}
                                graphQL={COUNT_BY_SCREENCLASS}
                                type={'screenClass'}
                                title={"Screen Sizes"}
                                tenantId={getUserTenant().id}
                                subTitle={"Amount of unique users separated by screen size."}
                            />

                        </dl>
                    </div>
                </div>
            </div>
        </main>
    )
}
